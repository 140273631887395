import { useState } from 'react'

import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'

import {
  useAddresses,
  useDepartmentsMenu,
  useUser,
  useVisitor
} from '@k_frontend/core'
import { SkipLink } from '@k_frontend/ui'
import { Header } from 'components/Header'
import MiniCart from 'components/MiniCart/MiniCart'
import MiniPreCart from 'components/MiniPreCart/MiniPreCart'
import TopBannerContainer from 'containers/topBanner/topBanner'
import { getCookie } from 'cookies-next'
import { useCart } from 'hooks/useCart'
import { Logout } from 'services/logout'
import Kernel from 'settings/kernel'
import { shallow } from 'zustand/shallow'

import { FooterLoading } from 'components/Footer/Footer.loading'
import { LayoutProps as Props } from './types'

const Newsletter = dynamic(() => import('components/Newsletter'), {
  ssr: false
})

const FooterContainer = dynamic(
  () => import('containers/Footer').then((module) => module.FooterContainer),
  {
    loading: () => <FooterLoading />,
    ssr: false
  }
)

export default function LayoutController({
  children,
  cookieIsMobile,
  cookieIsPrime,
  hasTopBannerClosed
}: Props) {
  const router = useRouter()
  const productsInCart = useCart((state) => state.productsInCart, shallow)
  const { pathname } = router
  const searchVariant = getCookie('searchVariation') || Kernel.SEARCH_VARIANT
  const [isOpenTopBanner, setIsOpenTopBanner] = useState(true)
  const hasTopBanner = pathname === '/home' ? isOpenTopBanner : false

  const cleanFooterRoutes = [
    '/precarrinho/[...slug]',
    '/limite-excedido',
    '/oferta-esgotada'
  ]

  const hasDrawerRoutes = {
    '/precarrinho/[...slug]': dynamic(
      () =>
        import(
          /* webpackChunkName: `PreCheckoutDrawer` */ 'components/PreCheckout/Drawer'
        )
    )
  }

  const isClean = cleanFooterRoutes.includes(pathname)
  const removeHeaderAndFooter = [
    '/500',
    '/portaldeprivacidade',
    '/bgs',
    '/bgs/agendamentos',
    '/bgs/ticket',
    '/bgs/checkin'
  ]

  const mainContentId = pathname === '/home' ? 'main-campaign' : 'main-content'

  const renderNewsLetter = !isClean && <Newsletter isPrime={cookieIsPrime} />

  const renderSkipLinks = (
    <>
      <SkipLink id={mainContentId} />
      <SkipLink id='footer' text='Ir para o rodapé' />
    </>
  )

  function renderDrawer() {
    if (Object.keys(hasDrawerRoutes).includes(pathname)) {
      const Component = hasDrawerRoutes[pathname]

      return <Component cookieIsMobile={cookieIsMobile} />
    }

    return null
  }

  if (removeHeaderAndFooter.includes(pathname)) {
    return <>{children}</>
  }

  return (
    <>
      {renderSkipLinks}
      <div id='header-container'>
        {pathname === '/home' && (
          <TopBannerContainer
            isOpen={isOpenTopBanner}
            setIsOpen={setIsOpenTopBanner}
            isPrime={cookieIsPrime}
            hasClosed={hasTopBannerClosed}
          />
        )}
        <Header
          clean={false}
          kernel={Kernel}
          isPrime={cookieIsPrime}
          useUser={useUser}
          useVisitor={useVisitor}
          useAddresses={useAddresses}
          useDepartmentsMenu={useDepartmentsMenu}
          cookieIsMobile={cookieIsMobile}
          logout={Logout}
          key={productsInCart.length}
          DesktopLogo={
            cookieIsPrime ? (
              <Image
                style={{ height: `100%` }}
                width='158'
                height='64'
                src={`${Kernel.STATIC_KABUM}/conteudo/icons/logoPrime.svg`}
                alt='Logo Kabum prime'
              />
            ) : (
              <Image
                style={{ height: `100%` }}
                width='158'
                height='64'
                src={`${Kernel.STATIC_KABUM}/conteudo/icons/logo.svg`}
                alt='Logo Kabum'
              />
            )
          }
          MobileLogo={
            cookieIsPrime ? (
              <Image
                width='105'
                height='36'
                src={`${Kernel.STATIC_KABUM}/conteudo/icons/logoPrime.svg`}
                alt='Logo Kabum prime'
              />
            ) : (
              <Image
                width='105'
                height='36'
                src={`${Kernel.STATIC_KABUM}/conteudo/icons/logo.svg`}
                alt='Logo Kabum'
              />
            )
          }
          searchVariant={String(searchVariant)}
          hasTopBanner={hasTopBanner}
          pathname={pathname}
        />
      </div>
      <MiniCart />
      <MiniPreCart />
      <div id='main-content'>{children}</div>
      {renderNewsLetter}
      <FooterContainer cookieIsMobile={cookieIsMobile} />
      {renderDrawer()}
    </>
  )
}
